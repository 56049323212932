<template>
    <v-row class="ma-3" wrap>
        <v-col cols="12" md="6" sm="12">
            <v-text-field ref="nome" label="Nome" placeholder="Escreva o nome do avaliado" required outlined dense
                v-model.trim="avaliado.nome" data-vv-as="nome" data-vv-scope="avd-avaliado" name="nome" persistent-hint
                :hint="errors.first('avd-avaliado.nome')" :error="errors.collect('avd-avaliado.nome').length ? true : false"
                v-validate="'required'" data-vv-validate-on="change" id="nome" hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <v-text-field ref="email" label="E-mail" placeholder="Escreva o e-mail do avaliado" required outlined dense
                v-model.trim="avaliado.email" data-vv-as="email" data-vv-scope="avd-avaliado" name="email" persistent-hint
                :hint="errors.first('avd-avaliado.email')"
                :error="errors.collect('avd-avaliado.email').length ? true : false" v-validate="'required|min:8|email'"
                data-vv-validate-on="change" id="email" :rules="[validators.emailValidator]"
                hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <v-text-field ref="cargo" label="Cargo" placeholder="Escreva o cargo do avaliado" required outlined dense
                v-model.trim="avaliado.cargo" data-vv-as="cargo" data-vv-scope="avd-avaliado" name="cargo" persistent-hint
                :hint="errors.first('avd-avaliado.cargo')"
                :error="errors.collect('avd-avaliado.cargo').length ? true : false" v-validate="'required|min:3'"
                data-vv-validate-on="change" id="cargo" hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <v-text-field ref="setor" label="Setor" placeholder="Escreva o setor do avaliado" required outlined dense
                v-model.trim="avaliado.setor" data-vv-as="setor" data-vv-scope="avd-avaliado" name="setor" persistent-hint
                :hint="errors.first('avd-avaliado.setor')"
                :error="errors.collect('avd-avaliado.setor').length ? true : false" v-validate="'required|min:3'"
                data-vv-validate-on="change" id="setor" hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <v-text-field ref="salario" label="Salário bruto" placeholder="Escreva o salário do avaliado" required outlined
                dense v-model="avaliado.salario_valor" data-vv-as="salario" prefix="R$" data-vv-scope="avd-avaliado"
                persistent-hint :hint="errors.first('avd-avaliado.salario')"
                :error="errors.collect('avd-avaliado.salario').length ? true : false" data-vv-validate-on="change"
                v-validate="'required'" name="salario" id="salario" v-mask="[
                    '###,###',
                    '#.###,##',
                    '##.###,##',
                    '###.###,##',
                    '#.###.###,##',
                    '##.###.###,##',
                    '###.###.###,##',
                    '#.###.###.###,##',
                ]" hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <v-text-field ref="data_admissao" label="Data Admissão" placeholder="Escreva a data de admissão do avaliado"
                required outlined dense v-model="convertData" data-vv-as="data admissão" name="data_admissao"
                persistent-hint :hint="errors.first('avd-avaliado.data_admissao')" :error="errors.collect('avd-avaliado.data_admissao').length ? true : false
                    " data-vv-validate-on="change" data-vv-scope="avd-avaliado" v-validate="'required'" id="data_admissao"
                v-mask="['##/##/####']" hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <v-autocomplete label="Nivel" :items="listaNiveis" item-value="id" item-text="descricao" dense outlined
                hide-details="auto" v-model="avaliado.id_nivel" no-data-text="Nenhum dado disponível" name="id_nivel"
                data-vv-as="nível" data-vv-scope="avd-avaliado" persistent-hint
                :hint="errors.first('avd-avaliado.id_nivel')"
                :error="errors.collect('avd-avaliado.id_nivel').length ? true : false" data-vv-validate-on="change"
                id="nivel" v-validate="'required'" ref="nivel">
                <template v-slot:selection="data">
                    <span>{{ data.item.descricao | capitalize() }}</span>
                </template>
                <template v-slot:item="data">
                    <span>{{ data.item.descricao | capitalize() }}</span>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <div class="d-flex align-center">
                <div class="me-2">
                    <v-list-item-title class="text-sm">
                        Avaliado é gestor do setor?
                    </v-list-item-title>
                </div>
                <v-spacer></v-spacer>
                <v-switch class="mt-0" v-model="avaliado.gestor" persistent-hint :hint="errors.first('avd-avaliado.gestor')"
                    :error="errors.collect('avd-avaliado.gestor').length ? true : false" v-validate="'required'"
                    data-vv-validate-on="change" data-vv-as="gestor" data-vv-scope="avd-avaliado" hide-details="auto">
                </v-switch>
            </div>
        </v-col>
        <v-col cols="12" md="6" sm="12" class="mt-1">
            <v-text-field ref="equipe" label="Equipe" placeholder="Escreva da equipe do avaliado" required outlined dense
                v-model.trim="avaliado.equipe" data-vv-as="equipe" data-vv-scope="avd-avaliado" name="equipe"
                persistent-hint :hint="errors.first('avd-avaliado.equipe')"
                :error="errors.collect('avd-avaliado.equipe').length ? true : false" v-validate="'required'"
                data-vv-validate-on="change" id="equipe" hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12" class="mt-1">
            <v-autocomplete label="Gestor" :items="listaGestores" item-value="id" item-text="nome" dense outlined
                hide-details="auto" v-model="avaliado.id_gestor" no-data-text="Nenhum dado disponível"
                data-vv-as="id gestor" data-vv-scope="avd-avaliado" id="gestor" ref="gestor"></v-autocomplete>
        </v-col>
    </v-row>
</template>

<script>
import store from "@/store";
import { computed } from "@vue/composition-api";
import { required, emailValidator } from "@core/utils/validation";

import { getCurrentyDatePtBr, unMaskDate } from "@/helpers";

export default {
    name: "FormColaboradores",
    inject: ["$validator"],
    setup() {
        // Computed
        const avaliado = computed({
            get() {
                return store.getters["avdColaboradores/getColaborador"];
            },
            set(value) {
                store.dispatch("avdColaboradores/setColaborador", value);
            },
        });
        const listaNiveis = computed({
            get() {
                return store.getters["avdNiveis/getNiveis"];
            },
        });
        const avaliados = computed(
            () => store.getters["avdColaboradores/getColaboradores"]
        );
        const listaGestores = computed(() => {
            const arrayGestores = [
                {
                    id: null,
                    nome: "Nenhum Gestor",
                },
            ];

            if (avaliados.length < 1) return arrayGestores;

            const arrayGestoresFiltred = avaliados.value.filter(
                (el) => el.gestor == 1
            );

            return [...arrayGestores, ...arrayGestoresFiltred];
        });
        const convertData = computed({
            get() {
                const dataAdmissao = avaliado.value.data_admissao
                    ? getCurrentyDatePtBr(avaliado.value.data_admissao)
                    : null;

                return dataAdmissao;
            },
            set(value) {
                avaliado.value.data_admissao = unMaskDate(value);
            },
        });

        // Variables
        const validators = {
            required,
            emailValidator,
        };

        // Function
        function validate() {
            return this.$validator.validate("avd-avaliado.*");
        }

        return {
            // Computed
            avaliado,
            listaNiveis,
            avaliados,
            listaGestores,
            convertData,

            // Variables
            validators,

            // Function
            validate,
        };
    },
};
</script>