<template>
  <div class="colaboradores-component">
    <v-card flat class="mt-0" elevation="0">
      <v-card-text>
        <Crud :title="'Avaliados'" buttonAddItemText="Novo avaliado" :tamanhoModalCrud="800"
          searchLabelText="Pesquisar na lista" :formTitle="nameForm" :callBackModalCancelar="fechar" :dialog.sync="dialog"
          :callBackModalSalvar="salvar" :loadingButtonSave="loadings.loadingButtonSave" :dialogDelete.sync="dialogDelete">
          <template slot="addButtonsArea">
            <v-btn @click="btnExportar()" color="secondary" outlined class="mb-4 ml-2" large>
              <v-icon size="17" class="me-1"> mdi-tray-arrow-up </v-icon>
              <span>Importar</span>
            </v-btn>
            <v-btn color="secondary" outlined class="mb-4 ml-2" target="_blank" :href="modeloPlanilhaImportacao" large>
              <v-icon size="17" class="me-1"> mdi-tray-arrow-down </v-icon>
              <span>Baixar Planilha Modelo</span>
            </v-btn>
          </template>
          <template slot="data-table">
            <v-data-table :headers="headers" :items="avaliados" :loading="loadingTable" class="table-kitchen-sink"
              items-per-page-text="Linhas por página" no-results-text="Nenhum registro encontrado"
              loading-text="Carregando... Por favor, espere" elevation="0" :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': 'Linhas por página',
              }" mobile-breakpoint="0" no-data-text="Nenhum registro encontrado">
              <!-- NÍVEL -->
              <template v-slot:[`item.id_nivel`]="{ item }">
                <span>
                  {{ retornaNiveis(item.id_nivel) | capitalize() }}
                </span>
              </template>
              <!-- CARGO -->
              <template v-slot:[`item.cargo`]="{ item }">
                <span>
                  {{ item.cargo | capitalize() }}
                </span>
              </template>
              <!-- SETOR -->
              <template v-slot:[`item.setor`]="{ item }">
                <span>
                  {{ item.setor | capitalize() }}
                </span>
              </template>
              <!-- DATA ADMISSÃO -->
              <template v-slot:[`item.data_admissao`]="{ item }">
                <span>
                  {{ item.data_admissao | dateFormatBr() }}
                </span>
              </template>
              <!-- STATUS -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip small :color="item.status == 'A' ? 'success' : 'secondary'">
                  {{ item.status == "A" ? "Ativo" : "Inativo" }}
                </v-chip>
              </template>
              <!-- GESTOR -->
              <template v-slot:[`item.gestor`]="{ item }">
                <v-chip small :color="item.gestor == true ? 'warning' : 'info'">
                  {{ item.gestor == true ? "Sim" : "Não" }}
                </v-chip>
              </template>
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="editar(item)">
                              <v-icon class="actions"> mdi-pencil </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Remover -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="apagar(item, true)">
                              <v-icon class="actions"> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </template>
          <!-- Modal de criar crud -->
          <template slot="contentModalCrud">
            <v-container fluid>
              <Form></Form>
            </v-container>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn :loading="loadings.loadingButtonDelete" color="error" @click="apagar()">
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
        </Crud>
      </v-card-text>
    </v-card>

    <!-- Importar planilha -->
    <ModalLateral :show="modalLateral.show" :hide="btnExportar" :title="modalLateral.title" :headers="headerModalLateral"
      :item="modalLateral.item">
      <template v-slot:[`item.arquivo`]="{ item }">
        <v-row>
          <v-col cols="12">
            <v-file-input hide-details outlined
              accept=".csv, .xls, xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              placeholder="Tamanho máximo aceito é de 2MB" v-model="arquivo" @change="validaArquivos" show-size
              @click:clear="clearArquivo()"></v-file-input>
            <p class="font-weight-medium error--text">
              {{ mensagemErroArquivo != null ? mensagemErroArquivo : "" }}
            </p>
          </v-col>
          <v-col cols="12">
            <p class="mb-2 mb-sm-0">Dados do arquivo anexado</p>
            <v-spacer></v-spacer>
            <table class="billing-address-table">
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">
                    Arquivo:
                  </p>
                </td>
                <td>
                  <p>
                    {{ dadosArquivo.nome != null ? dadosArquivo.nome : "--" }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">
                    Tamanho:
                  </p>
                </td>
                <td>
                  <p>
                    {{
                      dadosArquivo.tamanho != null ? dadosArquivo.tamanho : "--"
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="billing-title">
                  <p class="text-no-wrap text--primary font-weight-medium">
                    Tipo:
                  </p>
                </td>
                <td>
                  <p>
                    {{ dadosArquivo.tipo != null ? dadosArquivo.tipo : "--" }}
                  </p>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12">
            <v-btn color="secondary" outlined class="mt-4" block large :disabled="dadosArquivo.habilitarProcessamento"
              @click="processarArquivo" :loading="isLoadingBtnProcess">
              <v-icon class="mr-1"> mdi-file-send-outline </v-icon>
              <span>Processar planilha</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ModalLateral>
  </div>
</template>
<script>
import store from "@/store";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import Form from './components/colaboradoresComponents/Form.vue'
import themeConfig from "@themeConfig";
import { formatBytes } from "@/helpers";
import { required, emailValidator } from "@core/utils/validation";

import { ref, computed, onMounted } from "@vue/composition-api";

import { unMaskNumber } from '@/helpers';

export default {
  inject: ["$validator"],
  name: "colaboradores-Component",
  components: { Crud, ModalLateral, Form },
  setup(_, { emit }) {
    // Lifecyle
    onMounted(async () => {
      await findByColumns()

      await store.dispatch("avdNiveis/initialize")

      loadingTable.value = false;
    })

    // Variables
    const modeloPlanilhaImportacao = themeConfig.modulos.avd.modeloDownload;
    const nameForm = ref("Novo avaliado");
    const dialog = ref(false);
    const modalLateral = ref({
      show: false,
      item: [],
      title: "",
    });
    const headerModalLateral = ref([
      { text: "Planilha de avaliados", value: "arquivo" },
    ]);
    const loadingTable = ref(true);
    const loadings = ref({
      loadingButtonSave: false,
      loadingButtonDelete: false,
    });
    const isLoadingBtnProcess = ref(false);
    const headers = ref([
      { text: "Nome", value: "nome" },
      { text: "Gestor", value: "gestor" },
      { text: "Nível", value: "id_nivel" },
      { text: "Cargo", value: "cargo" },
      { text: "Setor", value: "setor" },
      { text: "Admissão", value: "data_admissao" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ]);
    const arquivo = ref(null);
    const mensagemErroArquivo = ref(null);
    const dadosArquivo = ref({
      nome: null,
      tamanho: null,
      tipo: null,
      habilitarProcessamento: true,
    });
    const tipoPermitidosArquivos = ref([
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]);
    const dialogDelete = ref(false);

    // Computed
    const avaliacao = computed(() => store.getters["avd/getAvaliacao"]);
    const avaliados = computed(
      () => store.getters["avdColaboradores/getColaboradores"]
    );
    const avaliado = computed({
      get() {
        return store.getters['avdColaboradores/getColaborador'];
      },
      set(value) {
        store.dispatch('avdColaboradores/setColaborador', value);
      }
    })
    const listaNiveis = computed({
      get() {
        return store.getters['avdNiveis/getNiveis'];
      }
    });

    // Function
    function salvar() {
      this.$validator.validate("avd-avaliado.*").then((result) => {
        if (result) {
          loadings.value.loadingButtonSave = true;
          const isEdit = avaliado.value.id != undefined ? 1 : -1;

          let url =
            isEdit === -1
              ? "/avd/colaborador"
              : `/avd/colaborador/${avaliado.value.uuid}`;
          let method = isEdit === -1 ? "POST" : "PUT";
          let sucesso =
            isEdit === -1
              ? "Avaliado cadastrado com sucesso."
              : "Avaliado atualizado com sucesso.";
          let erro =
            isEdit === -1
              ? "Erro ao salvar avaliado."
              : "Erro ao atualizar avaliado.";

          let colaboradorItem = avaliado.value;
          colaboradorItem._method = method;
          colaboradorItem.url = url;
          colaboradorItem.salario_valor = unMaskNumber(colaboradorItem.salario_valor, 'RE');
          colaboradorItem.id_cliente =
            avaliacao.value.id_cliente != null
              ? avaliacao.value.id_cliente
              : null;
          colaboradorItem.id_filial =
            avaliacao.value.id_filial != null
              ? avaliacao.value.id_filial
              : null;

          store
            .dispatch("avdColaboradores/save", colaboradorItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                if (isEdit === -1) {
                  avaliados.value.push(response);
                } else {
                  const index = avaliados.value.findIndex(
                    (el) => el.id == colaboradorItem.id
                  );
                  Object.assign(avaliados.value[index], colaboradorItem);
                }
                store.dispatch("snackBarModule/openSnackBar", {
                  color: "success",
                  timeout: 3000,
                  text: sucesso,
                });
              }
            })
            .catch(() => {
              store.dispatch("snackBarModule/openSnackBar", {
                color: "danger",
                timeout: 3000,
                text: erro,
              });
            })
            .finally(() => {
              fechar();
              loadings.value.loadingButtonSave = false;
            });
        }
      });
    }
    function retornaNiveis(id_nivel = Number) {
      if (listaNiveis.length < 1) return 'Sem nível';

      const nivel = listaNiveis.value.find((el) => el.id === id_nivel);

      return nivel && typeof nivel == 'object' ? nivel.descricao : 'Sem nível';
    };
    function btnExportar() {
      if (modalLateral.value.show == false) {
        modalLateral.value.show = true;
        modalLateral.value.title = "Importar Avaliados";
      } else {
        modalLateral.value.show = false;
        modalLateral.value.title = "";
      }
    };
    function editar(item) {
      nameForm.value = "Editar avaliado: " + item.nome;
      loadingTable.value = true;

      store.dispatch('avdColaboradores/findByUuid', item.uuid).then(response => {
        avaliado.value = response;
        dialog.value = true;

      }).catch(() => {
        store.dispatch("snackBarModule/openSnackBar", {
          color: "error",
          timeout: 5000,
          text: "Houve um erro ao buscar pelos dados do colaborador. Por favor, tente novamente ou entre em contato com o suporte.",
        });
      }).finally(() => {
        loadingTable.value = false;
      })

    };
    function apagar(item, openModal = false) {
      if (openModal) {
        avaliado.value = Object.assign({}, item);
        dialogDelete.value = true;
      } else {
        loadings.value.loadingButtonDelete = true;
        const indexItem = avaliados.value.findIndex(
          (el) => el.uuid == avaliado.value.uuid
        );

        store
          .dispatch("avdColaboradores/remove", avaliado.value.uuid)
          .then((response) => {
            if (response == true) {
              avaliados.value.splice(indexItem, 1);

              store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Colaborador removido com sucesso.",
              });
            }
          })
          .catch(() => {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o colaborador.",
            });
          })
          .finally(() => {
            loadings.value.loadingButtonDelete = false;
            dialogDelete.value = false;
            fechar();
          });
      }
    };
    function fechar() {
      dialog.value = false;
      nameForm.value = "Novo avaliado ";
      avaliado.value = {
        nome: null,
        email: null,
        cargo: null,
        setor: null,
        gestor: false,
        id_gestor: null,
        id_nivel: null,
        id_filial: null,
        id_cliente: null,
        data_admissao: null,
        status: "A",
        salario: null,
      }
    };
    function validate() {
      return true;
    };
    function validaArquivos() {
      if (!arquivo.value) return

      // Valida tamanho do arquivo importado
      let isLt2M = arquivo.value.size / 1024 / 1024 < 2;
      mensagemErroArquivo.value = !isLt2M ? "Tamanho excede o permitido" : "";

      // Valida tipo do arquivo importado
      let arquivoPermitido = tipoPermitidosArquivos.value.find(
        (i) => arquivo.value.type === i
      );
      mensagemErroArquivo.value =
        arquivoPermitido == undefined || arquivoPermitido == null
          ? "Tipo do arquivo não aceito"
          : "";

      let tipoArquivo = arquivo.value.name.split(".");
      dadosArquivo.value.nome = arquivo.value.name;
      dadosArquivo.value.tamanho = arquivo.value && arquivo.value.size ? formatBytes(arquivo.value.size) : null;
      dadosArquivo.value.tipo = tipoArquivo[tipoArquivo.length - 1];
      dadosArquivo.value.habilitarProcessamento = false;
    };
    function processarArquivo() {
      isLoadingBtnProcess.value = true;
      let form = new FormData();

      form.append("planilha_colaboradores", arquivo.value);
      form.append("id_cliente", avaliacao.value.id_cliente);
      form.append("id_filial", avaliacao.value.id_filial);

      store
        .dispatch("avdColaboradores/importCollaborators", form)
        .then(async (response) => {
          if (response) {
            loadingTable.value = true;

            await findByColumns();

            loadingTable.value = false;

            store.dispatch("snackBarModule/openSnackBar", {
              color: "success",
              timeout: 3000,
              text: "Colaboradores cadastrados com sucesso.",
            });
          }
        })
        .catch(() => {
          store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 3000,
            text: "Houve um erro ao cadastrar os colaboradores. Por favor, mais tarde tente novamente.",
          });
        })
        .finally(() => {
          isLoadingBtnProcess.value = false;
          modalLateral.value.show = false;
        });
    };
    function clearArquivo() {
      dadosArquivo.value = {
        nome: null,
        tamanho: null,
        tipo: null,
        habilitarProcessamento: true,
      }
      arquivo.value = null;
      mensagemErroArquivo.value = null
    }
    /**
     * @description Retorna todos os dados de acordo com a validação e colunas passadas.
     * 
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com> 
    */
    async function findByColumns() {
      const dataColaborador = {
        columns: [
          "id",
          "uuid",
          "nome",
          "email",
          "gestor",
          "id_gestor",
          "id_nivel",
          "data_admissao",
          "setor",
          "cargo",
          "status",
        ],
        id_avaliacao: avaliacao.value.id,
        validate: avaliacao.value.id_cliente
          ? [["id_cliente", avaliacao.value.id_cliente]]
          : [["id_filial", avaliacao.value.id_filial]],
      }

      await store.dispatch("avdColaboradores/findByColumns", dataColaborador)

      return
    }

    return {
      //Variables
      modeloPlanilhaImportacao,
      nameForm,
      dialog,
      modalLateral,
      headerModalLateral,
      loadingTable,
      loadings,
      isLoadingBtnProcess,
      headers,
      arquivo,
      mensagemErroArquivo,
      dadosArquivo,
      dialogDelete,
      validators: {
        required,
        emailValidator,
      },

      // Function
      salvar,
      retornaNiveis,
      btnExportar,
      editar,
      apagar,
      fechar,
      validate,
      validaArquivos,
      processarArquivo,
      clearArquivo,

      // Computed
      avaliacao,
      avaliados,
      avaliado,
      listaNiveis,
    };
  },
};
</script>

<style lang="scss"></style>
