import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"colaboradores-component"},[_c(VCard,{staticClass:"mt-0",attrs:{"flat":"","elevation":"0"}},[_c(VCardText,[_c('Crud',{attrs:{"title":'Avaliados',"buttonAddItemText":"Novo avaliado","tamanhoModalCrud":800,"searchLabelText":"Pesquisar na lista","formTitle":_vm.nameForm,"callBackModalCancelar":_vm.fechar,"dialog":_vm.dialog,"callBackModalSalvar":_vm.salvar,"loadingButtonSave":_vm.loadings.loadingButtonSave,"dialogDelete":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialog=$event},"update:dialogDelete":function($event){_vm.dialogDelete=$event},"update:dialog-delete":function($event){_vm.dialogDelete=$event}}},[_c('template',{slot:"addButtonsArea"},[_c(VBtn,{staticClass:"mb-4 ml-2",attrs:{"color":"secondary","outlined":"","large":""},on:{"click":function($event){return _vm.btnExportar()}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" mdi-tray-arrow-up ")]),_c('span',[_vm._v("Importar")])],1),_c(VBtn,{staticClass:"mb-4 ml-2",attrs:{"color":"secondary","outlined":"","target":"_blank","href":_vm.modeloPlanilhaImportacao,"large":""}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" mdi-tray-arrow-down ")]),_c('span',[_vm._v("Baixar Planilha Modelo")])],1)],1),_c('template',{slot:"data-table"},[_c(VDataTable,{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.avaliados,"loading":_vm.loadingTable,"items-per-page-text":"Linhas por página","no-results-text":"Nenhum registro encontrado","loading-text":"Carregando... Por favor, espere","elevation":"0","footer-props":{
              showFirstLastPage: true,
              'items-per-page-text': 'Linhas por página',
            },"mobile-breakpoint":"0","no-data-text":"Nenhum registro encontrado"},scopedSlots:_vm._u([{key:"item.id_nivel",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.retornaNiveis(item.id_nivel)))+" ")])]}},{key:"item.cargo",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.cargo))+" ")])]}},{key:"item.setor",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.setor))+" ")])]}},{key:"item.data_admissao",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormatBr")(item.data_admissao))+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":item.status == 'A' ? 'success' : 'secondary'}},[_vm._v(" "+_vm._s(item.status == "A" ? "Ativo" : "Inativo")+" ")])]}},{key:"item.gestor",fn:function(ref){
            var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":item.gestor == true ? 'warning' : 'info'}},[_vm._v(" "+_vm._s(item.gestor == true ? "Sim" : "Não")+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editar(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1),_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.apagar(item, true)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions"},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar")])])],1)],1)],1)],1)]}}],null,true)})],1),_c('template',{slot:"contentModalCrud"},[_c(VContainer,{attrs:{"fluid":""}},[_c('Form')],1)],1),_c('template',{slot:"modalDelete/titleModal"},[_vm._v(" Excluir o registro? ")]),_c('template',{slot:"modalDelete/contentModal"},[_c('p',[_vm._v(" Atenção! Você está prestes a realizar uma ação que não pode ser desfeita. ")]),_c('p',[_vm._v("Você realmente deseja excluir esse registro?")])]),_c('template',{slot:"modalDelete/actionsModal"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Fechar")]),_c(VBtn,{attrs:{"loading":_vm.loadings.loadingButtonDelete,"color":"error"},on:{"click":function($event){return _vm.apagar()}}},[_vm._v(" Excluir ")]),_c(VSpacer,{staticClass:"d-none d-md-block"})],1)],2)],1)],1),_c('ModalLateral',{attrs:{"show":_vm.modalLateral.show,"hide":_vm.btnExportar,"title":_vm.modalLateral.title,"headers":_vm.headerModalLateral,"item":_vm.modalLateral.item},scopedSlots:_vm._u([{key:"item.arquivo",fn:function(ref){
            var item = ref.item;
return [_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VFileInput,{attrs:{"hide-details":"","outlined":"","accept":".csv, .xls, xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","placeholder":"Tamanho máximo aceito é de 2MB","show-size":""},on:{"change":_vm.validaArquivos,"click:clear":function($event){return _vm.clearArquivo()}},model:{value:(_vm.arquivo),callback:function ($$v) {_vm.arquivo=$$v},expression:"arquivo"}}),_c('p',{staticClass:"font-weight-medium error--text"},[_vm._v(" "+_vm._s(_vm.mensagemErroArquivo != null ? _vm.mensagemErroArquivo : "")+" ")])],1),_c(VCol,{attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-2 mb-sm-0"},[_vm._v("Dados do arquivo anexado")]),_c(VSpacer),_c('table',{staticClass:"billing-address-table"},[_c('tr',[_c('td',{staticClass:"billing-title"},[_c('p',{staticClass:"text-no-wrap text--primary font-weight-medium"},[_vm._v(" Arquivo: ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.dadosArquivo.nome != null ? _vm.dadosArquivo.nome : "--")+" ")])])]),_c('tr',[_c('td',{staticClass:"billing-title"},[_c('p',{staticClass:"text-no-wrap text--primary font-weight-medium"},[_vm._v(" Tamanho: ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.dadosArquivo.tamanho != null ? _vm.dadosArquivo.tamanho : "--")+" ")])])]),_c('tr',[_c('td',{staticClass:"billing-title"},[_c('p',{staticClass:"text-no-wrap text--primary font-weight-medium"},[_vm._v(" Tipo: ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.dadosArquivo.tipo != null ? _vm.dadosArquivo.tipo : "--")+" ")])])])])],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"mt-4",attrs:{"color":"secondary","outlined":"","block":"","large":"","disabled":_vm.dadosArquivo.habilitarProcessamento,"loading":_vm.isLoadingBtnProcess},on:{"click":_vm.processarArquivo}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v(" mdi-file-send-outline ")]),_c('span',[_vm._v("Processar planilha")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }